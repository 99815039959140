.NavBar-strip button:focus {
  outline: none;
}

.eds-button--secondary {
  background: none;
}

#product-attributes-search-wrapper .AuiInputLabel,
#classification-search-wrapper .AuiInputLabel {
  display: none;
}

.MuiInputBase-root {
  min-height: 58px;
}

.MuiInput-input {
  padding-bottom: 5px!important;
  padding-top: 5px !important;
}

.eds-hidden {
  display: none;
}

.eds-input-box textarea:focus {
  border-color: black !important;
}

.eds-scrim {
  z-index: 2;
}

.eds-va-t {
  vertical-align: top;
}

.eds-va-b {
  vertical-align: bottom;
}

.eds-ta-c {
  text-align: center;
}
.eds-ta-r {
  text-align: right;
}

.eds-gallery {
  max-width: 600px;
  width: 80vw;
}

.eds-gallery .slide {
  display: flex;
  justify-content: center;
}
.eds-gallery .gallery-image {
  background: #fcfcfc;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.eds-gallery .gallery-image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.eds-gallery .gallery-image * {
  display: block;
  left: 50%;
  max-height: 90%;
  max-width: 90%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.eds-gallery .eds-spinner {
  margin: 40px auto;
}
.eds--dark .eds-card {
  color: white;
}

.eds--dark .eds-card label {
  color: white;
}

.eds-card .eds-card-gray {
  background: #efefef;
}

.eds-card.eds-card-small {
  padding: 4px 8px;
}

.eds-type--title-5 svg {
  vertical-align: -20%;
}

.eds-link svg {
  vertical-align: -18%;
}

.product-image {
  border-radius: 8px;
  display: block;
  height: 48px;
  margin-right: 16px;
  overflow: hidden;
  position: relative;
  width: 48px;
}

.product-image.product-image-large {
  height: 160px;
  margin-right: 0;
  max-width: 100%;
  width: 160px;
}

.product-image.product-image-link {
  cursor: pointer;
}

.product-image * {
  display: block;
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.eds-snack-clean {
  background: none;
  box-shadow: none;
  padding: 0;
}

.eds-snack-small .eds-icon__wrapper {
  padding: 4px;
}

.eds-side-panel {
  align-content: space-between;
  background-color: var(--eds-color-white);
  box-shadow: var(--eds-elevation-shadow-1);
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  min-width: 448px;
  max-width: 600px;
  padding: var(--eds-space-32);
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 56px);
}

.eds-side-panel.eds-side-panel--large {
  max-width: 1600px;
}

.eds-side-panel__header {
  display: flex;
  margin: 0 0 var(--eds-space-24) 0;
}

.eds-side-panel__header > *:first-child {
  flex-grow: 1;
}

.eds-side-panel__content {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.eds-side-panel__content:before {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  display: block;
  height: 90px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.eds-side-panel__scrolltop:before {
  display: none;
} 

.eds-side-panel__content:after {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 90px;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.eds-side-panel__scrollbottom:after {
  display: none;
} 

.eds-side-panel__content > div {
  height: 100%;
  overflow-y: scroll;
}

.eds-side-panel__close {
  color: var(--eds-color-grey-1);
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.eds-side-panel__divider {
  background-color: var(--eds-color-grey-2);
  height: 1px;
  margin: var(--eds-space-24) 0; 
}

.eds-chip {
  align-items: center;
  background-color: var(--eds-color-grey-3);
}

.eds-chip:hover {
  background-color: var(--eds-color-grey-2);
}

.eds-accordion__divider {
  background-color: var(--eds-color-grey-2);
  height: 1px;
  margin: var(--eds-space-16) 0; 
}

.eds-accordion__header {
  cursor: pointer;
  display: flex;
}

.eds-accordion__content {
  overflow: hidden;
}

.eds-accordion__title {
  flex-grow: 1;
}

.eds-hr {
  background-color: var(--eds-color-grey-2);
  height: 1px;
  margin: var(--eds-space-12) 0; 
}

.eds-tabs-wrapper {
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.eds-tabs-wrapper:before {
  background: linear-gradient(90deg, #efefef 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 30px;
  z-index: 1;
}

.eds-tabs-wrapper__scrollleft:before {
  display: none;
} 

.eds-tabs-wrapper:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  content: "";
  display: block;
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  z-index: 1;
}

.eds-tabs-wrapper__scrollright:after {
  display: none;
} 

.eds-tabs-wrapper > div {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
}

.eds-tabs-wrapper > div::-webkit-scrollbar {
  display: none;
}

.eds-link {
  white-space: nowrap;
}